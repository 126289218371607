@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.listfireddrivers .upperarea {
    margin-top: 26px;
    margin-left: -30px;
    font-family: 'Roboto', sans-serif;
    color: black;
    width: 100%;
}

.listfireddrivers .upperarea li.name{
    font-size: 22pt;
    padding: 5px;

}

.listfireddrivers .upperarea ul li{
    list-style-type: none;
    display: inline-block;
    margin-top: -20px;
    margin-left: 5px;
}

.listfireddrivers table {
    width: 100%;
    border-collapse: collapse;

}

.listfireddrivers table th{
    padding: 8px 5px;
    border-bottom: 2px solid black;
    font-family: 'Roboto', sans-serif;
    color: black;
    font-size: 14pt;
    text-align: left;
}

.listfireddrivers table tr{
    background-color: rgb(238, 238, 238);
}

.listfireddrivers table tr.titles{
    background-color: #fff;
}

.listfireddrivers table tr:nth-child(even) {
    background-color: #fff;
}

.listfireddrivers table td{
    font-family: 'Roboto', sans-serif;
    color: black;
    border-bottom: 1px solid gainsboro;
    padding: 8px 5px;
}

.listfireddrivers table td a{
    color: #000;
    text-decoration: none;
}

.listfireddrivers table td a:hover{
    color: rgb(53, 53, 53);
}

