.middle-2{
    width: 980px;
    margin: 20px auto 0 auto;
    position: relative;
}

.middle-2-1{
    width: 60%;

}

.middle-2 h2{
    font-family: 'Rubik', sans-serif;
    font-size: 18pt;
    text-shadow: 1px 1px 1px #454545;
}

.middle-2 p{
    font-family: 'Rubik', sans-serif;
    font-size: 14pt;
    padding-right: 5px;
}

.middle-2-2 img{
    float: right;
    width: 40%;
    margin-top: -280px;
    margin-left: 10px;

}
