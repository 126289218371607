@charset "UTF-8";

div.personaldata {
    background-color: #f4f5f8;
    border-radius: 20px;
    width: calc(33% - 20px);
    float: left;
    margin: 10px;
}

div.personaldata h2 {
    padding-top: 10px;
    padding-left: 25px;
}

div.personaldata table {
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
}

div.personaldata table tr td {
    border-top: solid rgb(185, 185, 185) 1px;
    padding: 8px 5px;
}

div.personaldata table td.personalLeft {
    text-align: left;
}

div.personaldata table td.personalRight {
    text-align: right;
}


div.toptenmonth {
    background-color: #f4f5f8;
    border-radius: 20px;
    width: calc(33% - 20px);
    float: left;
    margin: 10px;
}

div.toptenmonth h2 {
    padding-top: 10px;
    padding-left: 25px;
}

div.toptenmonth table {
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
}

div.toptenmonth table tr td {
    border-top: solid rgb(185, 185, 185) 1px;
    padding: 8px 5px;
}

div.toptenmonth table td.toptenmonthposition {
    text-align: left;
    width: 20px;
}

div.toptenmonth table td.toptenmonthname {
    text-align: center;
}

div.toptenmonth table td.toptenmonthkm {
    text-align: right;
    width: 150px;
}
