@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

header.blueHeader {
  background: #1a1afd;
}

header.gapHeader {
  background: #2ecc71;
}

header.developmentHeader {
  background: #673ab7;
}

header.header {
  width: 100%;
  display: block;
  position: fixed;
  height: 42px;
  z-index: 1;
}

header.header div.logoImg img {
  height: 37px;
  margin-left: 5px;
  margin-top: 3px;
}

header.header div.barName {
  display: block;
  float: right;
  margin-top: -42px;
  margin-right: 15px;
  display: inline-block;
  height: 100%;
}

header.header div.barName ul li {
  list-style-type: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

header.header div.barName li span {
  color: #fff;
  padding: auto 0;
  font-size: 18pt;
  vertical-align: 3px;
  font-family: 'Roboto', sans-serif;
}

header.header div.barName a {
  text-decoration: none;
  padding: 5px;
}

header.header div.barName .dropdown-content {
  display: none;
  position: absolute;
  right: 0px;
  top: 45px;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

header.header div.barName .dropdown-content a {
  color: black;
  margin-left: 3px;
  margin-top: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 14pt;
  font-weight: 100;
}

header.header div.barName ul li:hover .dropdown-content {
  display: block;
}