@charset "UTF-8";

.passwordForm {
    font-family: 'Roboto', sans-serif;
    color: black;
    font-size: 14pt;
}

.passwordForm input {
    margin: 10px 0;
    border: none;
    outline: none;
}

.passwordForm .button {
    font-family: 'Roboto', sans-serif;
    display: block;
    background-color: #1a1afd; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    padding-left: 45px;
}
