@charset "UTF-8";

main.defaultMargin {
    display: block;
    position: absolute;
    width: calc(100% - 300px);
    height: calc(100% - 42px);
    left: 300px;
    top: 32px;
    z-index: -1;
}
