@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');


.areaname {
  margin-top: 36px;
  margin-left: -30px;
  font-family: 'Roboto', sans-serif;
  color: black;
  width: 100%;
}

.areaname li.name {
  font-size: 22pt;
  padding: 5px;

}

.areaname ul li {
  list-style-type: none;
  display: inline-block;
  margin-top: -20px;
  margin-left: 5px;
}

.mainInfo {
  width: calc(100% - 10px);
  overflow-x: hidden;
  padding: 5px;
  background: rgb(236, 236, 236);
  border-top: 2px solid gainsboro;
  border-bottom: 2px solid gainsboro;
}

.mainInfo .tripleform {
  margin: 2px 5px;
}

.mainInfo .tripleform .field {
  position: relative;
  margin: 25px 10px auto 10px;
  display: inline-block;
  width: calc(33% - 20px);
}

.mainInfo .tripleform .field input {
  width: 100%;
  font-family: 'Rubik', sans-serif;
  font-size: 12pt;
  padding: 5px;
  outline: none;
  border: 1.5px solid gainsboro;
  border-radius: 10px;
}

.mainInfo .tripleform .field select {
  width: calc(100% + 15px);
  font-family: 'Rubik', sans-serif;
  font-size: 12pt;
  padding: 5px;
  outline: none;
  border: 1.5px solid gainsboro;
  border-radius: 10px;
}

.mainInfo .tripleform .field label {
  position: absolute;
  top: -25px;
  left: 0;
  font-family: 'Rubik', sans-serif;
  font-size: 14pt;
}

.skills {
  background-color: #eee;
  font-family: 'Rubik', sans-serif;
  font-size: 12pt;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;

  width: calc(100% - 40px);
  border-bottom: none;
}

.games {
  background-color: #eee;
  font-family: 'Rubik', sans-serif;
  font-size: 12pt;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;

  width: calc(100% - 40px);
}

.borderCollapsible textarea {
  background-color: #eee;
  font-family: 'Rubik', sans-serif;
  font-size: 12pt;
  color: #444;
  padding: 18px;
  border: 2px solid gainsboro;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 10px;
  height: 200px;
  text-align: left;
  resize: none;
  outline: none;
  font-size: 15px;
  width: calc(100% - 50px);
}

.switch {
  font-family: 'Rubik', sans-serif;
  background-color: #eee;
}

.borderCollapsible .Collapsible__trigger {
  background-color: #eee;
  font-family: 'Rubik', sans-serif;
  font-size: 12pt;
  color: #444;
  cursor: pointer;
  padding: 18px;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  width: calc(100% - 40px);
}

.borderCollapsible .Collapsible {
  background-color: #eee;
  font-family: 'Rubik', sans-serif;
  font-size: 12pt;
  color: #444;
  cursor: pointer;
  padding: 18px;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  width: calc(100% - 40px);
}

.borderCollapsible {
  border-bottom: 2px solid gainsboro;
}

.switch span {
  vertical-align: 5px;
  margin-left: 4px;
}

.truckersmpname {
  color: rgb(107, 107, 253);
  margin-left: 4px;
}

.registeruser .button {
  font-family: 'Roboto', sans-serif;
  display: block;
  background-color: #2ecc71;
  /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  float: right;
  margin-right: 16px;
  margin-top: -59px;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  padding-left: 45px;
}