@charset "UTF-8";

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap');

body {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    margin-top: -10px;
}
