@charset "UTF-8";

.middle-1-apply {
  width: 100%;
  color: white;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.middle-1-apply h2 {
  text-align: center;
  font-family: 'Rubik', sans-serif;
  font-size: 36pt;
  font-weight: 100;
  color: white;
  text-shadow: 0px 0px 7px black;
}