@charset "UTF-8";


header {
  width: 100%;
}

.main-menu {
  display: block;
  background-color: #242424;
  width: 100%;
  height: 50px;
}

.margin-header {
  margin: 0 auto;
  width: 900px;
  position: relative;
}

.logo {
  margin-left: 5%;
  float: left;
  width: 70%;

}

.logo-img {
  height: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.social-media {
  color: white;
  font-size: 30px;
  margin: 5px;
}

.social-medias ul li {
  display: inline-block;
  list-style: none;
}

.social-medias {
  width: 25%;
  float: left;
}

.header-2 {
  margin: 0 auto;
  width: 900px;
  position: relative;
  box-shadow: 3px solid #000000;

}

nav.header {
  margin-left: -7.5px;
}

nav.header ul li {
  display: inline-block;
  list-style: none;

}

nav.header .menu a {
  padding: 11px 15px;
  text-decoration: none;
  color: white;
  font-family: 'Roboto', sans-serif;

}

nav.header .menu a.active {
  padding: 11px 15px;
  text-decoration: none;
  color: white;
  font-family: 'Roboto', sans-serif;
  border-bottom: 2px solid #0909f8;

}

nav.header .menu a:hover {
  text-decoration: none;
  border-bottom: 2px solid white;

}

nav.header .menu a.active:hover {
  border-bottom: 2px solid #4545ff;
}

.main-nav {
  width: 100%;
  height: 47px;
  background: #353535;
}

.as-access {
  float: right;
  margin-top: -41px;
  margin-right: 30px;
}

.as-access a {
  color: white !important;
  padding: 11px 15px;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;

}

.as-access a:hover {
  text-decoration: none;
  border-bottom: 2px solid white;
}