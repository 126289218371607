.middle-1 {
  width: 100%;
  color: white;
}

.container-all {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.middle-1 h2 {
  font-family: 'Rubik', sans-serif;
  font-weight: 100;
  font-size: 28pt;
  color: white;
  text-shadow: 0px 0px 7px black;
}

.middle-1 p {
  font-family: 'Rubik', sans-serif;
  font-weight: 100;
  font-size: 18pt;
  color: white;
  text-shadow: 0px 0px 7px black;
}

a.apply {
  color: white;
  margin-top: 2.5px;
  border-radius: 25px;
  padding: 12px;
  background: #242424;
  text-decoration: none;
  transition: 0.5s;
}

a.apply:hover {
  color: white;
  background: #353535;
  transition: 0.5s;
  text-shadow: none;
}