@charset "UTF-8";

.middle-3-apply{
    width: 980px;
    margin: 0 auto;
    position: relative;
    margin-top: -5px;
    padding: 20px;
    color: #ffffff;
}

.middle-3-apply p{
    text-align: center;
}

.middle-3-apply a{
    margin-top: 5px;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    color: #ffffff;
    border: 2px solid #ffffff;
    padding: 8px;
    border-radius: 5px;
}
