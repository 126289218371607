@charset "UTF-8";

.footer {
    background: #2b2a2a;
    width: 100%;
    height: 186px;
}

.footer-margin {
    margin: 0 auto;
    width: 900px;
    position: relative;
}

.logo-footer{
    float: left;
}

.logo-footer a{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.footer-others {
    float: right;
    padding: 30px;
    position: relative;
    text-align: center;
    color: white;
    font-family: 'Roboto', sans-serif;
}

.footer-others .social-medias-footer  ul li{
    display: inline-block;
    list-style: none;
}

.footer-others .social-medias-footer  ul{
    margin-right: 45px;
}

a.footer-a{
    color: white !important;
    text-decoration: none !important;
    font-family: 'Roboto', sans-serif !important;
}

a.footer-a:hover{
    color: #8f8f8f !important;
    border-bottom: 1px solid #8f8f8f !important;
}

.footer-copyright p{
    text-align: center;
    color: white;
}
