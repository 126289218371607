@charset "UTF-8";

.staff-background {
  width: 100%;
  background: #f8f8f8;
  padding-top: 20px;
  padding-bottom: 20px;
}

.staff-list {
  display: block;
  margin: 0 auto;
  width: 1040px;
}

.staff-list h1 {
  font-family: 'Roboto', sans-serif;
  color: black;
  text-align: left;
  font-weight: 300;
  font-size: 32pt;
}

.staff-profile {
  width: 250px;
  display: inline-block;
  border-radius: 25px;
  margin: 5px;
}

.staff-profile img {
  padding-top: 10px;
  display: block;
  width: 150px;
  border-radius: 50%;
  margin: 0 auto;
}

.staff-profile h2,
h3 {
  margin: 2.5px 0;
  font-family: 'Roboto', sans-serif;
  color: black;
  text-align: center;
  font-weight: 300;
}

.staff-profile h2 {
  font-size: 18pt;
}

.staff-profile h3 {
  font-size: 12pt;
}