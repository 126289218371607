@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.registerNotification {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: calc(100% + 10px);
  position: absolute;
  z-index: 2;
}

.registerNotification .containner {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 36%;
  background-color: white;
  border-radius: 15px;
}

.registerNotification .containner img {
  margin-top: 15px;
  height: 96px;
  margin-left: calc(50% - 48px);
}

.registerNotification .containner h1 {
  font-size: 24pt;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  margin-top: 0px;
}

.registerNotification .containner p {
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

.registerNotification .containner i {
  font-size: 24px;
  position: absolute;
  right: 15px;
  top: 15px;
  color: gray;
}