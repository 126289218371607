@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.manageDataName .field label, .manageDataName .field select{
    font-family: 'Rubik', sans-serif;
    font-size: 14pt;

}

.manageDataName .field label{
    margin-right: 10px;
}

.manageDataName table {
    margin-top: 20px;
}

.manageDataName a {
    color: #000;
    text-decoration: none;
}

.manageDataName table.datanametable tr td.dataname {
    border: none !important;
}

.manageDataName table.datanametable tr#tableheader th{
    border: none;
    border-bottom: 2px solid #000;
}

form div.fieldSpace {
    margin-bottom: 20px;
}

form div.fieldSpace label{
    font-weight: bold;
}

form div.fieldSpace input, form div.fieldSpace select {
    border: none;
    border-bottom: 1px solid gainsboro;
    font-family: 'Rubik', sans-serif;
    font-size: 14pt;
    width: 25%;
}


