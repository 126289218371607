@charset "UTF-8";

div#popup {
    position: absolute;
    right: 20px;

    padding: 20px;
    border-radius: 10px;
}

div.success {
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    color: #155724;
}

div.success p, div.success a {
    color: #155724;
}

div.failed {
    background-color: #f8d7da;
    border: 1px solid #f5c2c7;
    color: #842029;
}

div.failed p, div.failed a {
    color: #842029;
}

div.warning {
    background-color: #fff3cd;
    border: 1px solid #ffecb5;
    color: #664d03;
}

div.warning p, div.warning a {
    color: #664d03;
}

div#popup p{
    margin: 0;
}

div#popup a {
    position: absolute;
    margin-top: -15px;
    right: 10px;
}
