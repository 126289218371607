@charset "UTF-8";

main#travelsAdmin h1, p, td, h2 {
    font-family: 'Roboto', sans-serif;
    color: black;
}
main#travelsAdmin div.travelsLeft {
    width: calc(50%);
    float: left;
}

main#travelsAdmin div.travelsRight {
    width: calc(50%);
    float: right;
    border-collapse: collapse;
}

main#travelsAdmin div.travelsLeft table {
    font-family: 'Roboto', sans-serif;
    border-collapse: collapse;
    width: 100%;
}

main#travelsAdmin div.travelsLeft td, main#travelsAdmin div.travelsLeft th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

main#travelsAdmin div.travelsLeft tr:nth-child(even) {
    background-color: #dddddd;
}

main#travelsAdmin div.travelsLeft .PENDING th, .PENDING td{
    background-color: #fff3cd !important;
    color: #664d03;
}

main#travelsAdmin div.travelsLeft .APPROVED th, .APPROVED td{
    background-color: #d1e7dd !important;
    color: #0f5132;
}

main#travelsAdmin div.travelsLeft .REPROVED th, .REPROVED td{
    background-color: #f8d7da !important;
    color: #842029;
}

main#travelsAdmin div.travelsLeft .EVALUATION th, .EVALUATION td{
    background-color: #cff4fc!important;
    color: #055160;
}

main#travelsAdmin div.travelsRight div.travelInfo {
    margin-left: 20px;
    margin-right: 20px;
}

main#travelsAdmin div.travelsRight div.travelInfo h2{
    font-weight: 400;
    padding: 0;
    margin: 0;
}

main#travelsAdmin div.travelsRight div.travelInfo table{
    border-spacing: 0;
}

main#travelsAdmin div.travelsRight div.travelInfo table td{
    width: 20%;
    padding: 5px;
    margin: -1px;
}

main#travelsAdmin div.travelsRight div.travelInfo table tr:nth-child(even){
    background-color: #f2f2f2;
}

main#travelsAdmin div.travelsRight div.travelButtons {
    margin-top: 30px;
    margin-right: 10px;
    height: 49px;
    width: 100%;

}

main#travelsAdmin div.travelsRight div.travelButtons button{
    float: right;
    padding: 15px 32px;
    margin: 0 20px;
    font-family: 'Roboto', sans-serif;
    background: #1a1afd;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 16px;
}

