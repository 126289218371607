@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

div.login {
  margin: 0;
  padding: 0;
  background: url(../images/login-background.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 101.1vh;
}

div.loginbox {
  width: 30%;
  height: 25%;
  background-color: rgba(0, 0, 0, 0.75);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 25px;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
  padding: 40px
}

div.loginbox h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 24pt;
  color: white;
  text-align: center;
}

div.loginbox p {
  font-family: 'Roboto', sans-serif;
  font-size: 14pt;
  font-weight: 400;
  color: white;
  text-align: center;
}

div.loginbox img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}