@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.alterdata .button {
  font-family: 'Roboto', sans-serif;
  display: block;
  background-color: #2ecc71;
  /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  float: right;
  margin-right: 40px;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  padding-left: 45px;
}

.alterdata .field {
  padding: 5px;
  margin-left: 10px;
}

.alterdata .field label {
  left: 0;
  font-family: 'Rubik', sans-serif;
  font-size: 14pt;
}

.alterdata .field input {
  width: 200px;
  font-family: 'Rubik', sans-serif;
  font-size: 12pt;
  padding: 5px;
  margin-left: 5px;
  outline: none;
  border: 2px solid gainsboro;
  border-radius: 10px;
}

.alterdata .field select {
  width: 215px;
  font-family: 'Rubik', sans-serif;
  font-size: 12pt;
  padding: 5px;
  margin-left: 5px;
  outline: none;
  border: 2px solid gainsboro;
  border-radius: 10px;
}

.alterdata .field textarea {
  background-color: #eee;
  font-family: 'Rubik', sans-serif;
  font-size: 12pt;
  color: #444;
  padding: 18px;
  border: 2px solid gainsboro;
  border-radius: 10px;
  margin-top: 10px;
  height: 200px;
  text-align: left;
  resize: none;
  outline: none;
  font-size: 15px;
  width: 65vh;
}

.alterdata .message {
  position: absolute;
  margin-top: -20px;
  margin-left: 10px;
}

.alterdata .message .green {
  color: green;
}

.altergames .switchld {}

.altergames .switchld label span {
  vertical-align: 5px;
  margin-left: 4px;
  font-family: 'Rubik', sans-serif;
}

.alterdata .right {
  width: calc(50% - 20px);
  float: right;
  margin-top: 0px;
  margin-right: 20px;
}

.alterdata .left {
  width: calc(50% - 30px);
  float: left;
  margin-left: 20px;
  margin-top: 55px;
}