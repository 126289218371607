@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.listdriver h1.title {
  margin-top: 26px;
  margin-left: 20px;
  font-family: 'Roboto', sans-serif;
  color: black;
  width: calc(100%-20px);
}

.listdriver .left {
  width: 50%;
  float: left;
}

.listdriver .right {
  width: 50%;
  float: right;
}

.listdriver .maindata li {
  list-style-type: none;
  font-family: 'Roboto', sans-serif;
  font-size: 13pt;
}

.listdriver .maindata li a {
  text-decoration: none;
  color: black;
}

.listdriver .bold {
  font-weight: bold;
}

.listdriver textarea.observation {
  font-size: 13pt;
  width: calc(100% - 50px);
  background-color: #eee;
  font-family: 'Rubik', sans-serif;
  color: #444;
  padding: 18px;
  border: 2px solid gainsboro;
  border-radius: 10px;
  margin-top: 10px;
  height: 200px;
  text-align: left;
  resize: none;
  outline: none;
  font-size: 15px;
}

.listdriver .buttons {
  margin-top: -45px;
  margin-right: 15px;
  margin-left: 15px;
}

.listdriver .buttons div {
  margin: 40px auto;
}

.listdriver .buttons div button {
  width: calc(50% - 20px);
  height: 40px;
  font-size: 11pt;
  margin: 5px 10px;
  border: 2px solid gainsboro;
  border-radius: 10px;
  background-color: #eee;
  font-family: 'Roboto', sans-serif;
  outline: none;
}

.listdriver .buttons div a {
  padding: 15px 30px;
  font-size: 11pt;
  margin: 0 10px;
  border: 2px solid gainsboro;
  border-radius: 10px;
  background-color: #eee;
  font-family: 'Roboto', sans-serif;
  outline: none;
  color: black;
  text-decoration: none;
}

.listdriver .buttons div a.red {
  border: 2px solid #721c24;
  color: #721c24;
  background-color: #f8d7da;
}

.listdriver .buttons div a.warning {
  border: 2px solid #856404;
  color: #856404;
  background-color: #fff3cd;
}

.listdriver .gamesdriver {
  margin-top: 26px;
}

.listdriver .gamescollapsible {
  background-color: #fff !important;
  font-family: 'Rubik', sans-serif;
  font-size: 12pt;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;

  width: calc(100% - 40px);
}

.listdriver .Collapsible__trigger {
  background-color: #fff;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: 12pt;
  color: #444;
  cursor: pointer;
  padding: 18px;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  width: calc(100% - 40px);
}

.listdriver .Collapsible {
  background-color: #fff;
  font-family: 'Rubik', sans-serif;
  font-size: 12pt;
  color: #444;
  cursor: pointer;
  padding: 18px;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  width: calc(100% - 40px);
}

.listdriver .gamesdriver li {
  font-family: 'Roboto', sans-serif;
  list-style-type: none;
  padding: 2px;
  font-size: 12pt;
}

.listdriver .gamesdriver li.red {
  color: #dc3545;
}

.listdriver .gamesdriver li.green {
  color: #28a745;
}