@charset "UTF-8";

.middle-2-apply{
    width: 980px;
    margin: 0 auto;
    position: relative;
    margin-top: -5px;

}

.middle-2-apply h3, p, li{
    font-family: 'Roboto', sans-serif;
}

.middle-2-apply h3{
    text-align: center;

}

.middle-2-1-apply{
    width: 48%;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px;
    border-right: gray solid 1.2px;
    border-inline-start: 10px;
}

.middle-2-apply p.discord{
    text-align: center;
    margin-top: 20px;
}

.middle-2-1-apply a{
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    color: #000000;
    border: 2px solid #000000;
    padding: 8px;
    border-radius: 5px;

}

.middle-2-2-apply{
    float: right;
    width: 46%;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px;
    border-inline-start: 10px;
}
