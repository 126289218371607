@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

nav.lateralNavigation {
  display: block;
  height: 100%;
  width: 300px;
  background: #dbdbdb;
  position: fixed;
  overflow: auto;
  margin-top: 42px;
}

nav.lateralNavigation div.personalInfo {
  margin-top: 15px;
  color: #000;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

nav.lateralNavigation div.personalInfo h2 {
  font-weight: 500;
  font-size: 20pt;
  margin: 1px auto;
}

nav.lateralNavigation div.personalInfo h3 {
  font-weight: 400;
  font-size: 14pt;
}

nav.lateralNavigation div.navigation ul {
  margin: 10px 0 0 0;
  padding: 0;
}

nav.lateralNavigation div.navigation ul li {
  list-style-type: none;
  border-bottom: 1px solid rgb(146, 146, 146);
}

nav.lateralNavigation div.navigation ul li a {
  display: block;
  color: #000;
  text-decoration: none;
  font-size: 13pt;
  font-family: 'Roboto', sans-serif;
  padding: 5px 0 5px 25px;
}