.middle-3 h2 {
  font-family: 'Rubik', sans-serif;
  font-weight: 100;
  font-size: 28pt;
  text-shadow: 0px 0px 7px black;
}

.middle-3 p {
  font-family: 'Rubik', sans-serif;
  font-weight: 100;
  font-size: 18pt;
  text-shadow: 0px 0px 7px black;
}

.middle-3 ul li {
  list-style: none;
  font-family: 'Rubik', sans-serif;
  font-weight: 100;
  font-size: 14pt;
  padding-bottom: 2px;
  text-shadow: 0px 0px 7px black;
}